
import React, { useContext, useEffect, useState } from "react";
import ScreenContext, { Screen } from "../../../helpers/ScreenContext";
import * as AT from "../../../init";
import styles from "./Animals.less";

type Props = {};

const Animals = (props: Props): JSX.Element => {
    const [screen, setScreen] = useContext(ScreenContext);

    // If levels change at this point, user is done
    useEffect(() => {
        AT.leveller.once("level", () => {
            setScreen(Screen.WeighIn);

            // // Send to "weigh-in" on activity
            // setTimeout(() => {
            //     AT.forceplate.once(
            //         "active",
            //         () => setScreen(Screen.WeighIn)
            //     );
            // }, 1000);
        });
    }, []);

    return null;
};

export default Animals;

export {
    Animals,
    Props,
};
