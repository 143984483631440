import React, { useEffect, useState } from "react";
import styles from "./WithProgress.less";

type Props = {
    className?: string;
    progress: number;
    children: React.ReactNode;
};

const WithProgress: React.FC<Props> = (props: Props) => {

    const { progress: _progress } = props;

    const constrain_0_1 = constrain(0, 1);
    const progress = constrain_0_1(_progress);

    const wrapper_clip = progress >= 0.5
        ? "rect(auto, auto, auto, auto)"
        : "rect(0px, 100px, 100px, 50px)";

    const left_transform = `rotate(${Math.round(progress * 360)}deg)`;

    const right_transform = progress >= 0.5
        ? "rotate(180deg)"
        : `rotate(${Math.round(progress * 360)}deg)`;

    return (
        <div className={styles.WithProgress + ' ' + (props.className || '')}>
            <div
                className={styles.Progress}
                style={{
                    clip: wrapper_clip
                }}
            >
                <div
                    className={styles.left}
                    style={{
                        transform: left_transform
                    }}
                />
                <div
                    className={styles.right}
                    style={{
                        transform: right_transform
                    }}
                />
            </div>
            {props.children}
        </div>
    )
};

const constrain =
    (low: number, high: number) =>
    (n: number) =>
    Math.max(Math.min(high, n), low);

export default WithProgress;

export {
    WithProgress,
    Props,
};
