import React, { useState, useEffect } from 'react';
import styles from "./Background.less";



type Props = {
    transparent: boolean;
    blink: number | undefined;
};

const Background = ({ transparent, blink }: Props) => {

    const [border, setBorder] = useState(false);

    useEffect(() => {
        if (blink !== undefined)
        {
            setBorder(true);

            const t = setTimeout(() => setBorder(false), 300);
            return () => clearTimeout(t);
        }
    }, [blink]);

    const showBorder = border
        ? " " + styles.show
        : "";

    return (
        <div
            className={styles.Background}
            style={{
                backgroundColor: transparent ? "transparent" : "#0A0A0C"
            }}
        >
            <div className={styles.Border + " " + showBorder}></div>
        </div>
    );
};

export default Background;

export {
    Background,
    Props,
};
