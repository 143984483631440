
export function getReached(name: string) {
    var reached = localStorage.getItem(name);
    return !!reached;
}

export function setReached(name: string) {
    localStorage.setItem(name, 'true');
    animals[name].reached = true;
}

export function clear_animals() {
    for (var name in animals) {
        localStorage.removeItem(name);
    }
}

const INCREMENT = 100 / 3;

var __minmax_iter__ = 0;
const nextMin = () => INCREMENT * (__minmax_iter__ + 1);
const nextMax = () => INCREMENT * (++__minmax_iter__ + 1);

export var num_animals = 15;

type Names =
        "koala"
        | "dog"
        | "kangaroo"
        | "gazelle"
        | "jaguar"
        | "panda"
        | "wild_hog"
        | "lion"
        | "tiger"
        | "gorilla"
        | "anaconda"
        | "alligator"
        | "grizzly"
        | "polar_bear"
        | "the_beast";

type Animals = {
    [key: string]: {
        name: string;
        min: number;
        max: number | undefined;
        reached: boolean;
    }
};

export const animals: Animals = {
    koala: {
        name: 'koala',
        min: nextMin(),
        max: nextMax(),
        reached: getReached('koala')
    },
    dog: {
        name: 'dog',
        min: nextMin(),
        max: nextMax(),
        reached: getReached('dog')
    },
    kangaroo: {
        name: 'kangaroo',
        min: nextMin(),
        max: nextMax(),
        reached: getReached('kangaroo')
    },
    gazelle: {
        name: 'gazelle',
        min: nextMin(),
        max: nextMax(),
        reached: getReached('gazelle')
    },
    jaguar: {
        name: 'jaguar',
        min: nextMin(),
        max: nextMax(),
        reached: getReached('jaguar')
    },
    panda: {
        name: 'panda',
        min: nextMin(),
        max: nextMax(),
        reached: getReached('panda')
    },
    wild_hog: {
        name: 'wild_hog',
        min: nextMin(),
        max: nextMax(),
        reached: getReached('wild_hog')
    },
    lion: {
        name: 'lion',
        min: nextMin(),
        max: nextMax(),
        reached: getReached('lion')
    },
    tiger: {
        name: 'tiger',
        min: nextMin(),
        max: nextMax(),
        reached: getReached('tiger')
    },
    gorilla: {
        name: 'gorilla',
        min: nextMin(),
        max: nextMax(),
        reached: getReached('gorilla')
    },
    anaconda: {
        name: 'anaconda',
        min: nextMin(),
        max: nextMax(),
        reached: getReached('anaconda')
    },
    alligator: {
        name: 'alligator',
        min: nextMin(),
        max: nextMax(),
        reached: getReached('alligator')
    },
    grizzly: {
        name: 'grizzly',
        min: nextMin(),
        max: nextMax(),
        reached: getReached('grizzly')
    },
    polar_bear: {
        name: 'polar_bear',
        min: nextMin(),
        max: nextMax(),
        reached: getReached('polar_bear')
    },
    the_beast: {
        name: 'the_beast',
        min: nextMin(),
        max: undefined,
        reached: getReached("the_beast")
    },
};

export var SCALE_MAX = nextMin();
