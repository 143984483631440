import React, { useState, useEffect, useContext } from 'react';
import styles from "./Connect.less";
import * as AT from "../../../init";
import getId from '@alphatekas/pwr_core/dist/helpers/getId';
import ScreenContext, { Screen } from "../../../helpers/ScreenContext";

type Props = {

};

const Connect = (props: Props) => {

    const [code, setCode] = useState<string>("");
    const [screen, setScreen] = useContext(ScreenContext);

    useEffect(() => {
        setCode(generate_code());
    }, []);

    useEffect(() => {

        const id = getId();

        if (id !== null)
        {
            AT.forceplate.init(id);
            setScreen(Screen.WeighIn);
        }

        if (code !== "")
        {
            fetch(
                `http://banshee.alphatek.no:9001/get/${code}`,
                {
                    mode: "cors"
                }
            )
                .then(res => res.json())
                .then(res => {
                    localStorage.
                        setItem(
                            "alphapwr_id", JSON.stringify({
                                date: Date.now(),
                                value: res,
                            })
                        );

                    AT.forceplate.init(res);
                    setScreen(Screen.WeighIn);
                });
        }
    }, [code]);

    return (
        <div className={styles.Connect}>
            {code !== "" && (
                <div className={styles.Code}>
                    {code}
                </div>
            )}
        </div>
    );
};

const allowed = "abcdefghijklmnpqrstuvwxyz123456789";

function generate_code()
{
    const arr = [];

    for (let i = 0; i < 4; ++i)
    {
        const idx = Math.floor(Math.random() * allowed.length);
        arr.push(allowed[idx]);
    }

    return arr.join("").toUpperCase();
}

export default Connect;

export {
    Connect,
    Props,
};
