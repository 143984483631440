
const brand = {
    black: "#0a0a0c",
    black_lite: "#0d0d0f",
    white: "#ffffff",
    gold: "#f6b45e",
};

const ux = {
    green_light: "#85ffb6",
    green_medium: "#2f7c53",
    green_dark: "#1a3024",
    yellow_light: "#ffe456",
    yellow_medium: "#735b06",
    yellow_dark: "#242414",
    red_light: "#ff3d3d",
    red_medium: "#a80000",
    red_dark: "#351111",
};

export {
    brand,
    ux,
};
