import React, { useState, useEffect } from 'react';
import styles from "./Burger.less";

type Props = {};

const Burger: React.FC<Props> = (props: Props) => {

    const [show, setShow] = useState<boolean>(false);

    const toggle = () => setShow(ps => !ps);

    return (
        <div className={styles.Burger}>
            <div
                className={styles.IconWrapper}
                onClick={toggle}
            >
                <div className={styles.Line} />
                <div className={styles.Line} />
                <div className={styles.Line} />
            </div>
            <div className={styles.MenuWrapper + (show ? " show" : "")}>
                <div className={styles.Menu}>
                    <Item
                        name="Squat"
                        link="//pwr.alphatek.no"
                    />
                    <Item
                        name="Pull"
                        link="//animals.alphatek.no"
                        active
                    />
                    <Item
                        name="Jump"
                        link="//cmj.alphatek.no"
                    />
                    <Item
                        name="Balance"
                        link="#"
                        disabled
                    />
                </div>
            </div>
        </div>
    );
};

type ItemProps = {
    name: string;
    link: string;
    disabled?: boolean;
    active?: boolean;
};

const Item: React.FC<ItemProps> = (props: ItemProps) => {

    return (
        <div
            className={
                styles.Item +
                (props.disabled ? " disabled" : "") +
                (props.active ? " active" : "")
            }
            onClick={() => {
                location.href = props.link
            }}
        >
            <p>{props.name}</p>
        </div>
    )
};

export default Burger;

export {
    Burger,
    Props,
};
