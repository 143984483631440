import React, { useState, useEffect, useContext } from 'react';
import styles from "./WeighIn.less";
import WithProgress from '../../ux/WithProgress';
import moveSvg from "../../../ux/svg/icons/large/Move.svg";
import weightSvg from "../../../ux/svg/icons/large/Step on.svg";
import * as AT from "../../../init";
import ScreenContext, { Screen } from "../../../helpers/ScreenContext";
import match from '@korkje/match';
import Countdown from '../../ux/Countdown';

type Props = {

};

enum State                      // level, active
{
    _0_Nothing,                 // 0, false
    _1_LevellingPersonWeight,   // 0, true (!)
    _2_PersonWeight,            // 1, true
}

const WeighIn = (props: Props) => {

    const [state, setState] = useState(State._0_Nothing);
    const [progress, setProgress] = useState(0);

    const [screen, setScreen] = useContext(ScreenContext);


    const onLevel = (level: number | undefined) => {
        setState(ps => {
            if (level === undefined)
            {
                return State._0_Nothing;
            }
            return State._2_PersonWeight;
        });
    };

    const onProgress = (progress: number) => {
        setProgress(progress);
    }

    const onActive = (active: boolean) => {
        setState(ps => {
            if (active)
            {
                return State._1_LevellingPersonWeight;
            }
            return State._0_Nothing;
        });
    };

    useEffect(() => {
        if (AT.forceplate.last_active)
        {
            setState(State._1_LevellingPersonWeight);
        }

        AT.leveller.on("level", onLevel);
        AT.leveller.on("progress", onProgress);
        AT.forceplate.on("active", onActive);

        return () => {
            AT.leveller.off("level", onLevel);
            AT.leveller.off("progress", onProgress);
            AT.forceplate.off("active", onActive);
        };
    }, []);

    useEffect(() => {
        if (state === State._2_PersonWeight)
        {
            const t = setTimeout(
                () => setScreen(Screen.Animals),
                500
            );

            return () => clearTimeout(t);
        }
    }, [state]);

    return (
        <div className={styles.WeighIn}>
            <div>
                {/* Mega hack, please do this another way, me */}
                <div className={styles.Instruction + " active"}>
                    <div className={styles.Right} style={{ width: 96 }} />
                    <div className={styles.Left}>
                        <div className={styles.Header} style={{ fontSize: 96 }}>
                            Pull
                        </div>
                    </div>
                </div>
                <Instruction
                    active={state === State._0_Nothing}
                    step={1}
                    text={{
                        header: "Step on!",
                        description: "Get ready, and step onto AlphaPWR",
                        // iconText: "We'll wait while you pick up your weights"
                    }}
                    svg={weightSvg}
                />
                <Instruction
                    active={state !== State._0_Nothing}
                    step={2}
                    text={{
                        header: "Calibrating...",
                        description: "Try standing as still as you can while we calibrate!",
                        // iconText: "Almost there, keep standing still!"
                    }}
                    svg={moveSvg}
                    progress={
                        match(state)
                            .on(State._0_Nothing, 0)
                            .on(State._1_LevellingPersonWeight, progress)
                            .on(State._2_PersonWeight, 1)
                            .result()
                    }
                />
            </div>
        </div>
    );
};

type InstructionProps = {
    active: boolean;
    step: number;
    text: {
        header: string;
        description: string;
        // iconText: string;
    }
    svg: string;
    progress?: number;
};

const Instruction = (props: InstructionProps) => {

    return (
        <div className={styles.Instruction + (props.active ? " active" : "")}>
            <div className={styles.Right}>
                {props.progress !== undefined ? (
                    <WithProgress
                        className={styles.Icon}
                        progress={props.progress}
                    >
                        <img src={props.svg} />
                    </WithProgress>
                ) : (
                    <div className={styles.Icon}>
                        <img src={props.svg} />
                    </div>
                )}
                {/* <div className={styles.IconText}>
                    {props.text.iconText}
                </div> */}
            </div>
            <div className={styles.Left}>
                <div className={styles.Step}>
                    Step {props.step} of 2
                </div>
                <div className={styles.Header}>
                    {props.text.header}
                </div>
                <div className={styles.Description}>
                    {props.text.description}
                </div>
            </div>
        </div>
    );
};

export default WeighIn;

export {
    WeighIn,
    Props,
};
