import React, { useState, useEffect } from 'react';
import { clear_animals } from '../../../animals';
import logoSvg from "../../../ux/svg/icons/Logo.svg";
import Burger from './Burger';
import styles from "./TopBar.less";

type Props = {};

const TopBar: React.FC<Props> = (props: Props) => {

    const clearLocalStorage = () => {
        localStorage.clear();
        location.reload();
    };

    const clearAnimals = () => {
        clear_animals();
        localStorage.removeItem("MAX_WEIGHT_ADJ_ALL_TIME");
        localStorage.removeItem("LAST_MAX_WEIGHT_ADJ");
        location.reload();
    }

    return (
        <div className={styles.TopBar}>
            <div className={styles.LogoWrapper}>
                <div className={styles.LogoSymbol} onClick={clearLocalStorage}>
                    <img src={logoSvg}></img>
                </div>
                <div className={styles.RightWrapper}>
                    <div
                        className={styles.LogoType}
                        onClick={clearAnimals}
                    >
                        <p>AlphaPWR</p>
                    </div>
                    <Burger />
                </div>
            </div>
        </div>
    );
};

export default TopBar;

export {
    TopBar,
    Props,
};
