import Forceplate from "@alphatekas/pwr_core/dist/forceplate";
import Leveller from "@alphatekas/pwr_core/dist/leveller";

console.log("__INIT__");

const hz = 434.0277777777777;

const forceplate = new Forceplate({
    hz,
    active_s: 0.5,
    active_w: 10,
});

const leveller = new Leveller({
    seconds: 2,
    variance: 1,
    w_cutoff: 5,
    deps: {
        forceplate
    },
});

export {
    forceplate,
    leveller,
};
