import p5 from "p5";
import { animals, clear_animals, SCALE_MAX, setReached } from "./animals";
import { RunningStat } from "./helper";
import * as AT from "./init";

import "./index.less";
import { brand } from "./helpers/colors";

function sketch(p: p5)
{
    var IS_RECEIVING_DATA = false;

    var MAX_VARIANCE = 1;

    var LAST_WEIGHT = 0;
    var CURRENT_WEIGHT = 0;

    var WEIGHT_ADJ = 0;
    var LAST_WEIGHT_ADJ = 0;
    var MAX_WEIGHT_ADJ = 0;
    var LAST_MAX_WEIGHT_ADJ = 0;
    var MAX_WEIGHT_ADJ_ALL_TIME = 0;

    var key_MAX_WEIGHT_ADJ_ALL_TIME = 'MAX_WEIGHT_ADJ_ALL_TIME';
    var key_LAST_MAX_WEIGHT_ADJ = 'LAST_MAX_WEIGHT_ADJ';

    {
        let res = localStorage.getItem(key_LAST_MAX_WEIGHT_ADJ);
        if (res !== null) {
            LAST_MAX_WEIGHT_ADJ = +res;
        }
    }
    {
        let res = localStorage.getItem(key_MAX_WEIGHT_ADJ_ALL_TIME);
        if (res !== null) {
            MAX_WEIGHT_ADJ_ALL_TIME = +res;
        }
    }

    var PERSON_HAS_LIFTED = false;

    var HAS_PERSON_WEIGHT = false;
    var PERSON_WEIGHT = 0;

    const state_drawNoPerson = 0;
    const state_drawPersonNoWeight = 1;
    const state_drawPersonIsLifting = 2;
    const state_drawPersonHasLifted = 3;
    const state_drawPersonWeight = 4;
    var drawState = state_drawNoPerson;

    AT.forceplate.on("connection", connected => {
        IS_RECEIVING_DATA = connected;

        if (!IS_RECEIVING_DATA) {
            reset();
        }
    });

    AT.forceplate.on("data", ({ weight }) => {
        LAST_WEIGHT = CURRENT_WEIGHT;
        CURRENT_WEIGHT = weight;
        processWeight();
    });

    AT.leveller.on("level", level => {
        if (level !== undefined)
        {
            drawState = state_drawPersonWeight;
            PERSON_WEIGHT = level;
            HAS_PERSON_WEIGHT = true;
        }
        else
        {
            drawState = state_drawNoPerson;
            PERSON_WEIGHT = 0;
            HAS_PERSON_WEIGHT = false;
        }
    })

    function keyTyped() {
        if (p.key === 'c') {
            if (window.confirm('Clear localstorage?')) {
                clear_animals();
                localStorage.removeItem("MAX_WEIGHT_ADJ_ALL_TIME");
                localStorage.removeItem("LAST_MAX_WEIGHT_ADJ");
                location.reload();
            }
        }
    }

    type Images = {
        [key: string]: {
            normal: p5.Image;
            grayed: p5.Image;
            large: p5.Image;
        };
    };

    var images: Images = {};

    var normal = require("./img/*.png");
    var grayed = require("./img/grayed/*.png");
    var large = require("./img/large/*.png");

    function preload() {
        for (let animal in animals) {
            images[animal] = {
                normal: p.loadImage(normal[animal]),
                grayed: p.loadImage(grayed[animal]),
                large: p.loadImage(large[animal]),
            }
        }
    }

    function setup() {
        p.createCanvas(p.windowWidth, p.windowHeight);
    }

    const stat = new RunningStat();
    let STAT_NUM_DATA = 0;
    let STAT_NUM_DATA_LAST = 0;

    function draw() {
        p.background(brand.black);

        // drawHelper(`
        //     <img src="${large["the_beast"]}">
        //     <h1><b>Wow, you pulled ${(500).toFixed()} kg!<b></h1>
        //     <h2>You are as strong as ${formatWithPre("the_beast")}!</h2>
        // `);

        p.noStroke();
        p.fill('#3c3c3c');
        p.rect(15, getY(SCALE_MAX) - 30, 30, getY(0) - getY(SCALE_MAX) + 60, 15);
        p.fill(IS_RECEIVING_DATA ? '#0090ff' : '#DADADA');
        p.rect(
            15,
            getY(WEIGHT_ADJ),
            30,
            getY(0) - getY(WEIGHT_ADJ) + 30,
            15
        );

        // Animals
        for (let name in animals) {
            const animal = animals[name];
            var reached = animal.reached;

            if (!reached && WEIGHT_ADJ > animal.min) {
                setReached(name);
                reached = true;
            }

            var current = drawState === state_drawPersonIsLifting
                ? getAnimal().name === name
                : false;

            p.imageMode(p.CENTER);
            var y = getY(animal.min || 0);
            p.image(
                images[name][reached ? 'normal' : 'grayed'],
                85,
                y,
                current ? 65 : 55,
                current ? 65 : 55
            );

            if (reached) {
                p.noStroke();
                p.fill(current ? '#0090ff' : "#fff");
                p.textFont('monospace', 17);
                p.textStyle(p.BOLD);
                p.text(format(name), 130, y);

                p.fill(255);
                p.textFont('monospace', 14);
                p.textStyle(p.NORMAL);
                p.text(animal.min.toFixed() + ' kg', 130, y + 18);
            }
        }

        drawStatBox();
        if(drawState == state_drawNoPerson) {
            drawNoPerson();
        } else if(drawState == state_drawPersonHasLifted) {
            drawPersonHasLifted();
        } else if(drawState == state_drawPersonIsLifting) {
            drawPersonIsLifting();
        } else if(drawState == state_drawPersonWeight) {
            drawPersonWithWeight();
        }
    }

    function reset() {
        stat.clear();
        PERSON_HAS_LIFTED = false;
        PERSON_WEIGHT = 0;
        LAST_WEIGHT_ADJ = 0;
        WEIGHT_ADJ = 0;
        MAX_WEIGHT_ADJ = 0;
    }

    function processWeight() {
        if (CURRENT_WEIGHT < 10) {
            // Nobody on weight
            HAS_PERSON_WEIGHT = false;
            drawState = state_drawNoPerson;

            // Reset
            if (LAST_WEIGHT >= 10 && MAX_WEIGHT_ADJ !== 0) {
                LAST_MAX_WEIGHT_ADJ = MAX_WEIGHT_ADJ;
                localStorage.setItem(
                    key_LAST_MAX_WEIGHT_ADJ,
                    LAST_MAX_WEIGHT_ADJ.toString()
                );
            }
            reset();
        } else {
            if (HAS_PERSON_WEIGHT) {
                LAST_WEIGHT_ADJ = WEIGHT_ADJ;
                WEIGHT_ADJ = CURRENT_WEIGHT - PERSON_WEIGHT;
                WEIGHT_ADJ = 0.975 * LAST_WEIGHT_ADJ + 0.025 * WEIGHT_ADJ;
                if (WEIGHT_ADJ < 0) WEIGHT_ADJ = 0;
                if (WEIGHT_ADJ >= (100 / 3)) {
                    // Ongoing lift
                    MAX_WEIGHT_ADJ = Math.max(
                        WEIGHT_ADJ,
                        MAX_WEIGHT_ADJ
                    );
                    drawState = state_drawPersonIsLifting;
                } else if (LAST_WEIGHT_ADJ > (100 / 3)) {
                    // Just done with lift
                    if (MAX_WEIGHT_ADJ > MAX_WEIGHT_ADJ_ALL_TIME) {
                        localStorage.setItem(key_MAX_WEIGHT_ADJ_ALL_TIME, MAX_WEIGHT_ADJ.toString());
                        MAX_WEIGHT_ADJ_ALL_TIME = MAX_WEIGHT_ADJ;
                    }
                    PERSON_HAS_LIFTED = true;
                    drawState = state_drawPersonHasLifted;
                } else {
                    if (PERSON_HAS_LIFTED) {
                        drawState = state_drawPersonHasLifted;
                    } else {
                        drawState = state_drawPersonWeight;
                    }
                }
            }
        }
    }

    var middleRef = document.getElementById('middle');

    function drawNoPerson() {
        // drawHelper(`
        //     <span class="wiggle"><h1>Step onto <b><span class="blue">Alpha</span><span class="gray">PWR</span></b>!</h1></span>
        // `);
    }

    function drawPersonNoWeight() {
        // drawHelper(`
        //     <h1 class="loading">Please stand still</h1>
        // `);
    }

    function drawPersonWithWeight() {
        drawHelper(`
            <h1><b>Now <span class="blue">lift</span>!</b></h1>
        `);
    }

    function drawPersonIsLifting() {
        var animal = getMaxAnimal();
        drawHelper(`
            <img class="shake shake-constant" src="${large[animal.name]}">
            <h1><b>Go on, <span class="blue">PUSH</span> yourself!</b><h1>
        `);
    }

    function drawPersonHasLifted() {
        var animal = getMaxAnimal();
        drawHelper(`
            <img src="${large[animal.name]}">
            <h1><b>Wow, you pulled ${MAX_WEIGHT_ADJ.toFixed()} kg!<b></h1>
            <h2>You are as strong as ${formatWithPre(animal.name)}!</h2>
        `);
    }

    var _last_html: string = undefined;
    function drawHelper(_html: string) {
        if (_html !== _last_html) {
            middleRef.innerHTML = _html;
            _last_html = _html;
        }
    }

    var statboxRef = document.getElementById('stat-box');
    var _last_statbox: string = undefined;
    function drawStatBox() {
        if (_last_statbox === undefined) {
            statboxRef.style.display = 'flex';
        }
        var mw = MAX_WEIGHT_ADJ_ALL_TIME
            ? MAX_WEIGHT_ADJ_ALL_TIME.toFixed()
            : '-';
        var lw = LAST_MAX_WEIGHT_ADJ
            ? LAST_MAX_WEIGHT_ADJ.toFixed()
            : '-';
        var _html = `
            <div><b>Peak</b>: ${mw} kg</div>
            <div><b>Last</b>: ${lw} kg</div>
        `;
        if (_html !== _last_statbox) {
            statboxRef.innerHTML = _html;
            _last_statbox = _html;
        }
    }

    // Helpers

    function formatWithPre(str: string) {
        if (str === "the_beast") return "The Beast";
        var first = str[0];
        var pre = 'a ';
        if (['a', 'e', 'i', 'o', 'u', 'y'].includes(first)) pre = 'an ';
        return pre + str.split('_').join(' ');
    }

    function format(str: string) {
        return titleCase(str.split('_').join(' '));
    }

    function titleCase(str: string) {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ');
    }

    function getAnimal() {
        return getAnimal_Helper(WEIGHT_ADJ);
    }

    function getMaxAnimal() {
        return getAnimal_Helper(MAX_WEIGHT_ADJ);
    }

    function getAnimal_Helper(weight: number) {
        for (var name in animals) {
            var animal = animals[name];
            if (weight >= animal.min) {
                if (animal.max === undefined || weight < animal.max) {
                    return animal;
                }
            }
        }
        console.log(weight);
    };

    var PADDING = 0.08;
    var LEFT_PADDING_PX = 100;

    function getY(weight: number) {
        var pad = p.windowHeight * PADDING;
        var rem = p.windowHeight - 2 * pad;
        var unit = rem / SCALE_MAX;

        return pad + (SCALE_MAX - weight) * unit + 100;
    }

    function set_peak(peak: number) {
        MAX_WEIGHT_ADJ_ALL_TIME = peak || 0;
        localStorage.setItem(
            key_MAX_WEIGHT_ADJ_ALL_TIME,
            MAX_WEIGHT_ADJ_ALL_TIME.toString()
        );
        for (var name in animals) {
            if (MAX_WEIGHT_ADJ_ALL_TIME > animals[name].min) {
                setReached(name);
            }
        }
    }

    function windowResized() {
        p.resizeCanvas(p.windowWidth, p.windowHeight);
    }

    p.keyTyped = keyTyped;
    p.preload = preload;
    p.setup = setup;
    p.draw = draw;
    p.windowResized = windowResized;
}

new p5(sketch);
