export class RunningStat {

    public _counter: number;
    public _oldMean: number;
    public _newMean: number;
    public _oldSampleVariance: number;
    public _newSampleVariance: number;

    constructor() {
        this._counter = 0;
    }

    clear() {
        this._counter = 0;
    }

    push(x: number) {
        this._counter++;

        if (this._counter == 1) {
            this._oldMean = x;
            this._newMean = x;
            this._oldSampleVariance = 0.0;

        } else {
            this._newMean = this._oldMean + (x - this._oldMean) / this._counter;
            this._newSampleVariance = this._oldSampleVariance + (x - this._oldMean) * (x - this._newMean);

            this._oldMean = this._newMean;
            this._oldSampleVariance = this._newSampleVariance;
        }
    }

    numDataValues() {
        return this._counter;
    }

    mean() {
        return this._counter > 0 ? this._newMean : 0.0;
    }

    variance() {
        return ((this._counter > 1) ? this._newSampleVariance / (this._counter - 1) : 0.0);
    }

    standardDeviation() {
        return Math.sqrt(this.variance());
    }
}
